import { render, staticRenderFns } from "./FGRImport.vue?vue&type=template&id=10f815e4&scoped=true&v-if=this.user.loggedIn&"
import script from "./FGRImport.vue?vue&type=script&lang=js&"
export * from "./FGRImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f815e4",
  null
  
)

export default component.exports