<template v-if="this.user.loggedIn">
  <div class="container text-center">
      <b-alert :variant="alertState" show>{{alertText}}</b-alert>
    <b-form-file
      v-if="!finnished && !running"
      v-model="file"
      :state="Boolean(file)"
      placeholder="Eine Datei auswählen oder ablegen..."
      drop-placeholder="Datei hier reinziehen..."
    ></b-form-file>
    <b-button @click="upload()" class="mt-2" v-if="file && !running">Hochladen</b-button>
    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" v-if="running && !finnished"></b-spinner>
    <div class="d-flex justify-content-center">
      <div class="text-center" style="width:20s%;" v-if="finnished">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
          <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
        Upload abgeschlossen
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from 'moment'
import papa from "papaparse"

import { db, functions } from "../plugins/firebase"
import { set, ref, onValue }  from "firebase/database"
import { httpsCallable } from "firebase/functions";

export default {
  name: 'FGRImport',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

  data(){
    return{
      file: undefined,
      fileReader: new FileReader(),
      running: false,
      finnished: false,
      alertState: 'secondary',
      alertText: 'Lade Informationen ...'
    }
  },
  methods: {
    chunkArray(array, chunkSize) {
      const chunkedArray = [];
        
      while (array.length) {
        chunkedArray.push(array.splice(0, chunkSize));
      }

      return chunkedArray;
    },
    async writeToDB(chunk) {
      return set(ref(db, 'FGR/' + Date.now().toString()), chunk)
        .catch(err=>{console.log(err)})
    },
    async upload(){
      this.running = true
      this.fileReader.onload = async (e) => { 
        let data = await this.cleanData(e.target.result, 5)
        const parsed = await this.parse(data)
        const reduced = await this.reduceData(parsed)
        const chunks = this.chunkArray(reduced, 5000);

        if (chunks.length === 0) {
          return;
        }

        set(ref(db, 'FGR'), {});
        
        for await (const chunk of chunks) {
          await this.writeToDB(chunk);
        }

        const changeFGR = httpsCallable(functions, 'changeFGR');
        changeFGR()
          .then(()=>{
            this.saveDate()
            this.finnished = true
            })
          .catch(err=>{console.log(err)})
      }
      this.fileReader.readAsBinaryString(this.file)
    },
    parse(rawData){
      return new Promise((resolve, reject) => {
        papa.parse(rawData, {
          header: true,
          delimiter: "\t",
          newline: "\r",
          skipEmptyLines: true,
          complete: (result) => {
            resolve(result.data)
          },
          error: error => {
            reject(error)
          }
        })
      })
    },
    cleanData(data, toRemove){
      let arr = data.split('\r')
      arr.splice(0, toRemove)
      return arr.join('\r')
    },
    async reduceData(arr){
      var ret = []
      for await (const el of arr){
        ret.push({
          "shipStatus": el["Shipping Status"],
          "customerPONumber": el["Customer PO Number"],
          "applePart": el["Apple Part #"],
          "DNQuantity": el["DN Quantity"],
          "DNCreationDate": el["DN Creation Date"],
          "estimatedDeliveryDateonorbefore": el["Estimated Delivery Date on or before"],
          "DNValue": el["DN Value"]
        })
      }
      return ret
    },
    saveDate(){
      const date = moment().tz("Europe/Berlin").format('x')
      set(ref(db, '/pages/fgr'),{date: date})
    },
    loadData(){
      onValue(ref(db, '/pages/fgr'), (snapshot) => {
        const data = snapshot.val()
        const dateFromDB = moment(data.date, "x")
        if (moment().diff(dateFromDB, 'days') >= 1){
          this.alertState = 'danger'
          this.alertText = `Letztes Update: ${dateFromDB.format('DD.MM.YYYY HH:mm:ss')} Uhr`
        } else {
          this.alertState = 'success'
          this.alertText = `Letztes Update: ${dateFromDB.format('DD.MM.YYYY HH:mm:ss')} Uhr`
        } 
      });
    }
  },
  mounted(){
    this.loadData();
  }
}
</script>

<style scoped>

</style>
