<template v-if="this.user.loggedIn">
  <div>
    <b-container>
      <div class="m-3">
        <b-alert :variant="messageState" show v-if="message">{{message}}</b-alert>
        <b-card no-body>
          <b-card-header header-tag="nav" >
            <b-nav card-header tabs>
              <b-nav-item active ref="goal" @click="clicked('goal')">Ziele</b-nav-item>
              <b-nav-item ref="create"  @click="clicked('create')">Nutzer anlegen</b-nav-item>
              <b-nav-item ref="edit"  @click="clicked('edit')">Nutzer verwalten</b-nav-item>
            </b-nav>
          </b-card-header>

          <b-card-body v-if="localNav === 'goal'">
            <b-form>

             <b-form-group>
               <label>Jahresziel</label>
              <b-form-select v-model="year" :options="form.yearOpt" class="mb-1"></b-form-select>
              <b-form-input
                @input="changeInput('yearly')"
                id="yearly"
                v-model="form.goal.yearly"
                type="number"
                placeholder="Jahres Ziel"
                required
              ></b-form-input>
              <hr>
              <b-row>
                <b-col>
                <label>Januar</label><small class="ml-2">{{workingdays.jan}} Tage à {{Number(form.goal.mothly[1]/workingdays.jan).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="jan"
                  class="m-1"
                  v-model="form.goal.mothly[1]"
                  type="number"
                  placeholder="Januar Ziel"
                  required
                ></b-form-input>
                <label>Februar</label><small class="ml-2">{{workingdays.feb}} Tage  à {{Number(form.goal.mothly[2]/workingdays.feb).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="feb"
                  class="m-1"
                  v-model="form.goal.mothly[2]"
                  type="number"
                  placeholder="Februar Ziel"
                  required
                ></b-form-input>
                <label>März</label><small class="ml-2">{{workingdays.mar}} Tage à {{Number(form.goal.mothly[3]/workingdays.mar).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="mar"
                  class="m-1"
                  v-model="form.goal.mothly[3]"
                  type="number"
                  placeholder="März Ziel"
                  required
                ></b-form-input>
                <label>April</label><small class="ml-2">{{workingdays.apr}} Tage à {{Number(form.goal.mothly[4]/workingdays.apr).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="apr"
                  class="m-1"
                  v-model="form.goal.mothly[4]"
                  type="number"
                  placeholder="April Ziel"
                  required
                ></b-form-input>
                <label>Mai</label><small class="ml-2">{{workingdays.may}} Tage à {{Number(form.goal.mothly[5]/workingdays.may).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="may"
                  class="m-1"
                  v-model="form.goal.mothly[5]"
                  type="number"
                  placeholder="Mai Ziel"
                  required
                ></b-form-input>
                <label>Juni</label><small class="ml-2">{{workingdays.jun}} Tage à {{Number(form.goal.mothly[6]/workingdays.jun).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="jun"
                  class="m-1"
                  v-model="form.goal.mothly[6]"
                  type="number"
                  placeholder="Juni Ziel"
                  required
                ></b-form-input>
                </b-col>
                <b-col>
                <label>Juli</label><small class="ml-2">{{workingdays.jul}} Tage à {{Number(form.goal.mothly[7]/workingdays.jul).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="jul"
                  class="m-1"
                  v-model="form.goal.mothly[7]"
                  type="number"
                  placeholder="Juli Ziel"
                  required
                ></b-form-input>
                <label>August</label><small class="ml-2">{{workingdays.aug}} Tage à {{Number(form.goal.mothly[8]/workingdays.aug).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="aug"
                  class="m-1"
                  v-model="form.goal.mothly[8]"
                  type="number"
                  placeholder="August Ziel"
                  required
                ></b-form-input>
                <label>September</label><small class="ml-2">{{workingdays.sep}} Tage à {{Number(form.goal.mothly[9]/workingdays.sep).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="sep"
                  class="m-1"
                  v-model="form.goal.mothly[9]"
                  type="number"
                  placeholder="September Ziel"
                  required
                ></b-form-input>
                <label>Oktober</label><small class="ml-2">{{workingdays.oct}} Tage à {{Number(form.goal.mothly[10]/workingdays.oct).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="oct"
                  class="m-1"
                  v-model="form.goal.mothly[10]"
                  type="number"
                  placeholder="Oktober Ziel"
                  required
                ></b-form-input>
                <label>November</label><small class="ml-2">{{workingdays.nov}} Tage à {{Number(form.goal.mothly[11]/workingdays.nov).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="nov"
                  class="m-1"
                  v-model="form.goal.mothly[11]"
                  type="number"
                  placeholder="November Ziel"
                  required
                ></b-form-input>
                <label>Dezember</label><small class="ml-2">{{workingdays.dec}} Tage à {{Number(form.goal.mothly[12]/workingdays.dec).toLocaleString('de-DE')}} €</small>
                <b-form-input
                  @input="changeInput('monthliy')"
                  id="dec"
                  class="m-1"
                  v-model="form.goal.mothly[12]"
                  type="number"
                  placeholder="Dezember Ziel"
                  required
                ></b-form-input>
                </b-col>
              </b-row>
             </b-form-group> 
             <b-button variant="primary" @click="saveGoal()">Speichern</b-button>
            </b-form>
          </b-card-body>
          
          <b-card-body class="text-center" v-if="localNav === 'create'">
            <b-card-title>Nutzer anlegen</b-card-title>
            <b-form-group>
              <b-form-input
                id="email"
                v-model="form.createUser"
                type="email"
                placeholder="E-Mail-Adresse"
                required
                class="mb-1"
              ></b-form-input>
              <b-button variant="primary" @click="saveUser()" :disabled="savingUser">
                Speichern <b-spinner style="width: 1rem; height: 1rem;" label="small Spinner" v-if="savingUser"></b-spinner>
              </b-button>
            </b-form-group>
          </b-card-body>
          
          <b-card-body class="text-center" v-if="localNav === 'edit'">
            <b-card-title>Nutzer verwalten</b-card-title>
            <b-spinner label="Spinning" v-if="!Boolean(users.length)"></b-spinner>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="user in users" :key="user.uid">
                <span class="email text-left">{{user.email}}</span>
                <b-badge v-if="checkAdmin(user.customClaims)" variant="primary" pill>Admin</b-badge>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="mr-5" v-if="!checkAdmin(user.customClaims)">
                    <font-awesome-icon class="clickable" :icon="['fas', 'user-crown']" @click="showModalAdmin(user)" v-b-popover.hover.top="'Als Admin darf der Nutzer die Einstellungen verwalten. (Nutzer anlegen, Nutzer zum Admin machen, Nutzer löschen und Ziele festlegen)'"/></div>
                  <div><font-awesome-icon class="clickable" :icon="['fas', 'trash']" @click="showModalDelete(user)" v-b-popover.hover.top="'Nutzer löschen.'"/></div>
                </div>
              </b-list-group-item>
            </b-list-group>

          </b-card-body>
        </b-card>
      </div>
      <b-modal ref="delete-modal" hide-footer title="Nutzer Löschen">
        <p class="my-4">Möchtest du den Nutzer mit der E-Mail-Adresse: <strong>{{userDelete.email}}</strong> löschen?</p>
        <b-button class="mt-3" variant="outline-danger" block @click="$refs['delete-modal'].hide()">Nein</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="deleteUser(userDelete)">Ja</b-button>
      </b-modal>
      <b-modal ref="admin-modal" hide-footer title="Nutzer zum Admin machen">
        <p class="my-4">Möchtest du den Nutzer mit der E-Mail-Adresse: <strong>{{userAdmin.email}}</strong> zum Administrator machen?</p>
        <b-button class="mt-3" variant="outline-danger" block @click="$refs['admin-modal'].hide()">Nein</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="setAdmin(userAdmin)">Ja</b-button>
      </b-modal>
    </b-container>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment'
import businessDaysChecker from 'moment-business-days'
import { isHoliday } from 'feiertagejs';

import { db, functions } from "../plugins/firebase"
import { ref, set, onValue } from "firebase/database"
import { httpsCallable } from "firebase/functions";

export default {
  name: 'IncomingGoods',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch : {
    year:function(val) {
      this.workingdays = { 
        jan: this.workingDays(val, '1'),
        feb: this.workingDays(val, '2'),
        mar: this.workingDays(val, '3'),
        apr: this.workingDays(val, '4'),
        may: this.workingDays(val, '5'),
        jun: this.workingDays(val, '6'),
        jul: this.workingDays(val, '7'),
        aug: this.workingDays(val, '8'),
        sep: this.workingDays(val, '9'),
        oct: this.workingDays(val, '10'),
        nov: this.workingDays(val, '11'),
        dec: this.workingDays(val, '12'),
      }
    }
  },
  data(){
    return{
      data: undefined,
      message: null,
      messageState: null,
      localNav: 'goal',
      year:'',
      workingdays: {},
      users: [],
      userDelete: {},
      userAdmin: {},
      savingUser: false,
      form:{          
        yearOpt:[],
        goal:{
          yearly:'',
          mothly:{
            1:'',
            2:'',
            3:'',
            4:'',
            5:'',
            6:'',
            7:'',
            8:'',
            9:'',
            10:'',
            11:'',
            12:''
          }
        },
        createUser:''
      }
    }
  },
  methods: {
    loadData(){
       onValue(ref(db, '/settings'), (snapshot) => {
          let data = snapshot.val()

          this.data = data
          this.createOptsForGoal()
        });
    },
    clicked(data){
      this.localNav = data
      this.$refs.goal.querySelector('a').classList.remove('active')
      this.$refs.edit.querySelector('a').classList.remove('active')
      this.$refs.create.querySelector('a').classList.remove('active')
      this.$refs[data].querySelector('a').classList.add('active')
      if (data === 'edit'){
        this.getUsers();
      }
    },
    saveGoal() {
      if(!this.year){
        this.setMessage('Alle Felder müssen ausgefüllt sein.', 'error')
      } else if(!this.form.goal.yearly){
        this.setMessage('Alle Felder müssen ausgefüllt sein.', 'error')
      } else if(!this.form.goal.mothly[1] || 
                !this.form.goal.mothly[2] ||
                !this.form.goal.mothly[3] ||
                !this.form.goal.mothly[4] ||
                !this.form.goal.mothly[5] ||
                !this.form.goal.mothly[6] ||
                !this.form.goal.mothly[7] ||
                !this.form.goal.mothly[8] ||
                !this.form.goal.mothly[9] ||
                !this.form.goal.mothly[10] ||
                !this.form.goal.mothly[11] ||
                !this.form.goal.mothly[12] 
      ){
        this.setMessage('Alle Felder müssen ausgefüllt sein.', 'error')
      } else {
        console.log('write in db')
        set(ref(db, 'settings/goals/' + this.year), {
          goal: this.form.goal.yearly,
          month:{
            1:{goal:this.form.goal.mothly[1], daily: this.form.goal.mothly[1]/this.workingdays.jan},
            2:{goal:this.form.goal.mothly[2], daily: this.form.goal.mothly[2]/this.workingdays.feb},
            3:{goal:this.form.goal.mothly[3], daily: this.form.goal.mothly[3]/this.workingdays.mar},
            4:{goal:this.form.goal.mothly[4], daily: this.form.goal.mothly[4]/this.workingdays.apr},
            5:{goal:this.form.goal.mothly[5], daily: this.form.goal.mothly[5]/this.workingdays.may},
            6:{goal:this.form.goal.mothly[6], daily: this.form.goal.mothly[6]/this.workingdays.jun},
            7:{goal:this.form.goal.mothly[7], daily: this.form.goal.mothly[7]/this.workingdays.jul},
            8:{goal:this.form.goal.mothly[8], daily: this.form.goal.mothly[8]/this.workingdays.aug},
            9:{goal:this.form.goal.mothly[9], daily: this.form.goal.mothly[9]/this.workingdays.sep},
            10:{goal:this.form.goal.mothly[10], daily: this.form.goal.mothly[10]/this.workingdays.oct},
            11:{goal:this.form.goal.mothly[11], daily: this.form.goal.mothly[11]/this.workingdays.nov},
            12:{goal:this.form.goal.mothly[12], daily: this.form.goal.mothly[12]/this.workingdays.dec},
          }
        })
        .then(()=>{
          this.setMessage('Daten erfolgreich gespeichert.', 'success')
          this.resetForm()
        })
      }

    },
    changeInput(changed){
      if(changed === 'yearly'){
        Object.values(this.form.goal.mothly).forEach((element, index)=>{
          console.log(element)
          this.form.goal.mothly[index+1] = this.form.goal.yearly / 12
          
        })
      } else {
        this.form.goal.yearly = Object.values(this.form.goal.mothly).reduce((pv, cv) => parseInt(pv) + parseInt(cv), 0)
      }
    },
    createOptsForGoal(){
      moment().tz("Europe/Berlin")
      let thisYear = moment().year()
      if(this.data){
        let lastYearInObject = Object.keys(this.data.goals).reduce((a, b) => +a > +b ? +a : +b)
        if(lastYearInObject >= thisYear){
          thisYear = parseInt(lastYearInObject) + 1
        } 
      }
      this.year = thisYear
      this.form.yearOpt = [
        { value: thisYear, text: thisYear.toString() }
      ]
    },
    isComLineHoliday(month, day) {

      //feiertagJS fehlender  REFORMATIONSTAG   
      if (month === 9 && day === 31) {
        return true;
      } 

      if (month === 11 && day === 24) {
        return true;
      } else if (month === 11 && day === 31) {
        return true;
      } else {
        return false;
      }
    },
    workingDays(yearSet, monthSet){
      
      let year = moment().year()
      let month = moment().month()
      console.log()
      if(yearSet){
        year = yearSet
      }
      if(monthSet){
        month = monthSet -1
      }
      var dddate = new Date(year, month, 1)
      moment().tz("Europe/Berlin")
      const daysThisMonth =  moment(dddate).daysInMonth()
      let businessDays = 0

      for (var i = 1; i <= daysThisMonth; i++) {
        let isBusiness = businessDaysChecker(new Date(year, month, i)).isBusinessDay()
        let isItHoliday = isHoliday(new Date(year, month, i), 'SH')
        const isItComLineHoliday = this.isComLineHoliday(month, i);

        if(isBusiness && !isItHoliday && isItComLineHoliday === false){
          businessDays ++
        }
      }
      return businessDays
  
    },
    setMessage(message, state){
      if(state === 'success'){
        this.message = message
        this.messageState = 'success'
        setTimeout(() =>{
          this.message = ''
        }, 50000)
      } else if ( state === 'error'){
        this.message = message
        this.messageState = 'danger'
        setTimeout(() =>{
          this.message = ''
        }, 50000)
      }
    },
    resetForm(){
      this.form.goal = {
                        yearly:'',
                        mothly:{
                          1:'',
                          2:'',
                          3:'',
                          4:'',
                          5:'',
                          6:'',
                          7:'',
                          8:'',
                          9:'',
                          10:'',
                          11:'',
                          12:''
                        }
    }
    },
    saveUser(){
      if(this.form.createUser){
        this.savingUser = true
        const $this = this
        var addMessage = httpsCallable(functions, 'createUser');
        addMessage({ email: this.form.createUser })
          .then(() => {
            $this.setMessage('Nutzer wurde erstellt und erhält in kürze eine E-Mail.', 'success')
            $this.form.createUser= ''
            $this.savingUser = false
          })
          .catch(function(error) {
            console.log(error)
            $this.setMessage('Es ist ein Fehler aufgetreten.', 'error')
          });
      }else{
        this.setMessage('Bitte gib eine E-Mail-Adresse ein.', 'error')
      }
    },
    getUsers(){
      var getUsers = httpsCallable(functions, 'getUsers');
      getUsers()
      .then((users)=>{
        this.users = users.data.users
      })
      .catch(err=>{console.log(err)})
    },
    checkAdmin(state){
      if(!state){
        return false
      } else if(state.admin === true){
        return true
      } else {
        return false
      }
    },
    setAdmin(data){
      this.$refs['admin-modal'].hide()
      const sendData = {
        state: true,
        uid: data.uid
      }
       let setAdmin = httpsCallable(functions, 'setAdmin');
      setAdmin(sendData)
      .then((res)=>{
        console.log(res)
        this.getUsers()
      })
      .catch(err=>{console.log(err)})
      this.userAdmin = ""
    },
    deleteUser(data){
       this.$refs['delete-modal'].hide()
      const sendData = {
        uid: data.uid
      }
       let deleteUser = httpsCallable(functions, 'deleteUser');
      deleteUser(sendData)
      .then((res)=>{
        console.log(res)
        this.getUsers()
      })
      .catch(err=>{console.log(err)})
      this.$refs['delete-modal'].hide()
      console.log("delete", data)
      this.userDelete = ""
    },
    showModalDelete(data) {
      this.$refs['delete-modal'].show()
      this.userDelete = data
    },
    showModalAdmin(data) {
      this.$refs['admin-modal'].show()
      this.userAdmin = data
    },
  },
  async mounted(){
    this.loadData();
  } 
}
</script>

<style scoped>
.card a.nav-link,
.card li a {
  color: black !important;;
}
  .clickable:hover {
    cursor: pointer;
    color: rgb(163, 163, 163);
  }
  .email{
    min-width: 270px;
  }
</style>
