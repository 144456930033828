<template>
  <div style="margin-top: 80px;" id="app">
    <navbar style="position: fixed;top: 0;z-index: 1000;width: 100%;"></navbar>
      <router-view></router-view>
  </div>
</template>
<script>
import navbar from "./components/Navbar";

export default {
  components: {
    navbar
  }
};
</script>
<style>
  .card .table {
    margin-bottom: 0rem !important;
    }
  th, strong, h6, span, td {
    color: #5c5c5c;
  }
</style>