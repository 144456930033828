<template v-if="this.user.loggedIn">
  <div>
   <h6> Tag {{todayWorkingday}} von {{detectedBussinessdays}}</h6>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import businessDaysChecker from 'moment-business-days'
import { isHoliday } from 'feiertagejs';

export default {
  name: 'WorkingDays',
  data(){
    return{
      detectedBussinessdays: 0,
      todayWorkingday: 0
    }
  },
  props: {
    reduce: String
  },
  methods: {
    isComLineHoliday(month, day) {
      //feiertagJS fehlender  REFORMATIONSTAG   
      if (month === 9 && day === 31) {
        return true;
      } 

      if (month === 11 && day === 24) {
        return true;
      } else if (month === 11 && day === 31) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted(){
    let useMoment = moment().tz("Europe/Berlin")
      if (this.reduce === '1') {useMoment = moment().subtract(1, 'days')}
    const daysThisMonth =  useMoment.daysInMonth()
    const year = useMoment.year()
    let month = useMoment.month()
    const day = useMoment.date()
    let businessDays = 0
    let businessDaysToNow = 0

    for (var i = 1; i <= daysThisMonth; i++) {
      let isBusiness = businessDaysChecker(new Date(year, month, i)).isBusinessDay()
      let isItHoliday = isHoliday(new Date(year, month, i), 'SH')
      const isItComLineHoliday = this.isComLineHoliday(month, i);

      if(isBusiness && !isItHoliday && isItComLineHoliday === false){
        businessDays ++
        if(i <= day){
          businessDaysToNow ++
        }
      }
    }


    if(businessDaysToNow === 0){
      month --
      businessDays = 0

      for (i = 1; i <= daysThisMonth; i++) {
        let isBusiness = businessDaysChecker(new Date(year, month, i)).isBusinessDay()
        let isItHoliday = isHoliday(new Date(year, month, i), 'SH')

        if(isBusiness && !isItHoliday){
          businessDays ++
        }
      }
      businessDaysToNow = businessDays
    }

    this.todayWorkingday = businessDaysToNow
    this.detectedBussinessdays = businessDays

  }
}
</script>

<style scoped>
  h6 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
</style>
