<template v-if="this.user.loggedIn">
  <div>
    <!--ABLAGE: v-if="month || daySellout || dayVarious || focus" -->
    <div class="m-3">
      <b-alert variant="danger" class="text-center" show v-if="goals"
        >Es wurden für dieses Jahr noch keine Ziele eingetragen.</b-alert
      >
      <LastUpdate page="home" class="mt-3" />
      <b-row>
        <b-col>
          <b-card no-body>
            <template #header>
              <div class="d-flex align-items-center">
                <h6 class="mb-0">Monat</h6>
                <Workingdays class="ml-auto" reduce="1" />
              </div>
            </template>
            <Month :data="month" v-if="month !== null" />
          </b-card>
        </b-col>
        <b-col>
          <b-card no-body>
            <template #header>
              <div class="d-flex align-items-center">
                <h6 class="mb-0">Tag (Sellout)</h6>
                <Workingdays class="ml-auto" />
              </div>
            </template>
            <DaySellout :data="daySellout" v-if="daySellout !== null" />
          </b-card>
        </b-col>
        <b-col>
          <b-card no-body>
            <template #header>
              <h6 class="mb-0">Tag (Diverse)</h6>
            </template>
            <DayVarious :data="dayVarious" v-if="dayVarious !== null" />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <template #header>
              <h6 class="mb-0">Fokus & Aktion</h6>
            </template>
            <div>
              <Focus :data="focus" v-if="focus !== null" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

//components
import Workingdays from "../smallComponents/workingdays";
import Month from "./Home/Month";
import DaySellout from "./Home/DaySellout";
import DayVarious from "./Home/DayVarious";
import Focus from "./Home/Focus";
import LastUpdate from "../smallComponents/lastUpdate";
import { db } from "../plugins/firebase";
import { onValue, ref } from "firebase/database";

export default {
  name: "HomeComponent",
  components: {
    Workingdays,
    Month,
    DaySellout,
    DayVarious,
    Focus,
    LastUpdate,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  data() {
    return {
      month: null,
      daySellout: null,
      dayVarious: null,
      focus: null,
      goals: false,
    };
  },
  methods: {
    loadData() {
      onValue(ref(db, "/pages/home"), (snapshot) => {
        let data = snapshot.val();
        this.month = data.month;
        this.daySellout = data.daySellout;
        this.dayVarious = data.dayVarious;
        this.focus = data.focus;
      });
    },
    checkIfGoalIsSet() {
      onValue(ref(db, "/settings/goals"), (snapshot) => {
        let snap = snapshot.val();
        let years = Object.keys(snap);
        let thisYear = moment().year();
        if (years.includes(thisYear.toString())) {
          this.goals = false;
        } else {
          this.goals = true;
        }
      });
    },
  },
  mounted() {
    this.loadData();
    this.checkIfGoalIsSet();
  },
};
</script>

<style scoped>
.card-body {
  padding: 0 !important;
}
.card-body .table {
  margin-bottom: 0rem !important;
}
</style>

<style>
.list-group-item:hover {
  background-color: #fafafa;
}
</style>
