<template v-if="this.user.loggedIn">
  <div id="dn-creation">
    <div id="fixed-table-header" :style="{ display: displayStyle }">
      <table class="table">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Wert</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="m-3" v-if="data">
      <b-card no-body>
        <template #header>
          <div class="d-flex justify-content-center align-items-center">
            <div class="w-25">
              <b-form-input
                v-model="filter"
                placeholder="Suchen"
                type="search"
                debounce="500"
              ></b-form-input>
            </div>
          </div>
        </template>
        <b-table
          striped
          :items="data"
          :fields="fields"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
        </b-table>
      </b-card>
    </div>
    <div v-else class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../plugins/firebase";
import { ref, onValue } from "firebase/database";
import moment from "moment";

export default {
  name: "DNCreation",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: true,
      data: null,
      filter: "",
      displayStyle: "none",
      fields: [
        {
          key: "date",
          label: "Datum",
          sortable: true,
          filterByFormatted: true,
          formatter: (value) => moment.unix(value).format("DD.MM.YYYY"),
        },
        {
          key: "val",
          label: "Wert",
          sortable: true,
          filterByFormatted: true,
          formatter: (value) =>
            Number(value).toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
            }),
        },
      ],
    };
  },
  methods: {
    loadData() {
      onValue(
        ref(db, "pages/dnCreation"),
        (snapshot) => {
          let data = snapshot.val();
          data.sort((a, b) => (a.date < b.date ? 1 : -1));
          data = data.slice(0, 30);
          this.data = Object.values(data);
        }
      );
    },
    handleScroll() {
      if (window.scrollY > 135) {
        this.displayStyle = "block";
      } else {
        this.displayStyle = "none";
      }
    },
  },
  mounted() {
    this.loadData();

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#fixed-table-header {
  position: fixed;
  z-index: 1;
  top: 60px;
  width: 100%;
  left: 0;
  background-color: #fff;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-bottom: 2px solid #dee2e6;
}
#fixed-table-header table {
  width: 100%;
  margin-bottom: 0;
}
#fixed-table-header th {
  border-bottom: 0 !important;
}
</style>

<style>
#dn-creation .table th:nth-child(1),
#dn-creation .table th:nth-child(2) {
  width: 160px !important;
}
#dn-creation .table th:nth-child(4),
#dn-creation .table th:nth-child(5),
#dn-creation .table th:nth-child(6),
#dn-creation .table th:nth-child(7),
#dn-creation .table th:nth-child(8) {
  width: 120px !important;
}
</style>
