import Vue from 'vue';
import Login from '../components/Login'
import Home from '../components/Home'
import IncomingGoods from '../components/IncomingGoods'
import OnTheRoad from '../components/OnTheRoad'
import DNCreation from '../components/DNCreation'
import FGRImport from '../components/FGRImport'
import Settings from '../components/Settings'
import Router from 'vue-router';
import store from '../store'
import NotAllowed from '../components/NotAllowedView'

import { auth } from "../plugins/firebase"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
            path: '*',
            redirect: '/login' 
          },
          { 
            path: '/', 
            redirect: '/login' 
          },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        { 
            path: '/token/:token', 
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/notallowed',
            name: 'NotAllowed',
            component: NotAllowed,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/incoming-goods',
            name: 'incoming-goods',
            component: IncomingGoods,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/otr',
            name: 'otr',
            component: OnTheRoad,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dn-creation',
            name: 'dn-creation',
            component: DNCreation,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/fgr-import',
            name: 'fgr-import',
            component: FGRImport,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        }
]
});

router.beforeEach(async (to, from, next) => {

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresAdminRole = to.matched.some(record => record.meta.requiresAdminRole)
    const currentUser = await auth.currentUser
    const PermissionStatus = store.getters.user.loggedIn ? store.getters.user.data.admin: false

    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
    let isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
    isUser = true

    if ((to.name === 'login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'home' })
    else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
    else if (requiresAdminRole && PermissionStatus && requiresAuth && currentUser) next()
    else if (requiresAdminRole && !PermissionStatus && requiresAuth && currentUser) next({name: 'login'})
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()

})

export default router