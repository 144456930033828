import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAiPINjahJpiIhwiINl0k4_nvU0z0347p4",
  authDomain: "clgmbh-apple-trademaster.firebaseapp.com",
  databaseURL: "https://clgmbh-apple-trademaster-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "clgmbh-apple-trademaster",
  storageBucket: "clgmbh-apple-trademaster.appspot.com",
  messagingSenderId: "904120484524",
  appId: "1:904120484524:web:9e8f92a74fc41119031509"
};

const firebaseT4RConfig = {
  apiKey: "AIzaSyCT1DxvqYtBUZBrHudwA9K1SesnlMVlRZQ",
  authDomain: "clgmbh-trademaster4reseller.firebaseapp.com",
  databaseURL: "https://clgmbh-trademaster4reseller.firebaseio.com",
  projectId: "clgmbh-trademaster4reseller",
  storageBucket: "clgmbh-trademaster4reseller.appspot.com",
  messagingSenderId: "349448977251",
  appId: "1:349448977251:web:0fc48651f1b4d478933156"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig, "default");
const auth = getAuth(firebase);
const db = getDatabase(firebase);
const functions = getFunctions(firebase, region);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectDatabaseEmulator(db, 'localhost', 9002);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const firebaseT4R = initializeApp(firebaseT4RConfig, 'T4R');
const functionsT4R = getFunctions(firebaseT4R);

if (location.hostname === 'localhost') {
  connectFunctionsEmulator(functionsT4R, 'localhost', 5003);
}

export {firebase, auth, functions, region, db, firebaseT4R, functionsT4R} 