<template v-if="this.user.loggedIn">
  <div>
    <b-list-group flush>
      <b-list-group-item class="d-flex align-items-center"><strong>Ist</strong>  <span class="ml-auto">{{Number(data.is).toLocaleString('de-DE')}} €</span></b-list-group-item>
      <b-list-group-item class="d-flex align-items-center"><strong>Ziel Linear</strong>  <span class="ml-auto">{{Number(data.goalLinear).toLocaleString('de-DE')}} €</span></b-list-group-item>
      <b-list-group-item class="d-flex align-items-center"><strong>Ziel (%)</strong>  <span class="ml-auto">{{Number(data.goalPercentage).toLocaleString('de-DE')}} %</span></b-list-group-item>
      <b-list-group-item class="d-flex align-items-center"><strong>Ziel HR</strong> <span class="ml-auto">{{Number(data.goalExtrapolation).toLocaleString('de-DE')}} €</span></b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

export default {
  name: 'daySellout',
  data(){
    return{

    }
  },
  props: {
    data: Object
  },
  methods: {

  },
}
</script>

<style scoped>

</style>
