<template v-if="this.user.loggedIn">
  <div>
    <b-table striped hover :items="data" :fields="fields"></b-table>
  </div>
</template>

<script>


export default {
  name: 'FocusComponent',
  data(){
    return{
       fields: [
        {
          key: 'artNo',
          label: 'Art. Nr..',
          sortable: true,
        },
        {
          key: 'SKU',
          label: 'SKU',
          sortable: true,
        },
        {
          key: 'discription',
          label: 'Bezeichnung',
          sortable: true
        },
        {
          key: 'comment',
          label: 'Bemerkung',
          sortable: true
        },
        {
          key: 'stock',
          label: 'Lager',
          sortable: true
        },
        {
          key: 'reserved',
          label: 'Reserviert',
          sortable: true
        },
        {
          key: 'available',
          label: 'Frei',
          sortable: true,
          variant: 'danger'
        },
        {
          key: 'ordered',
          label: 'Bestellt',
          sortable: true
        }
       ]
    }
  },
  props: {
    data: Array
  },
}
</script>

<style scoped>

</style>
