<template v-if="this.user.loggedIn">
  <div class="text-center">
      <b-alert :variant="alertState" show>{{alertText}} 
        <span v-if="Boolean(metaDates)">
          <b-icon icon="info-circle-fill" scale="1" :variant="alertState" id="tooltip-target-1"></b-icon>  
          <b-tooltip target="tooltip-target-1" triggers="hover" style="max-width: 350px; ">
            Komplettumsatz: {{metaDates.KomplettumsatzMetaData.date}} Uhr<br>
            Type 1: {{metaDates.type1MetaData.date}} Uhr<br>
            <!-- Type 2: {{metaDates.type2MetaData.date}} Uhr<br> -->
            Type 3: {{metaDates.type3MetaData.date}} Uhr<br>
            Type 4: {{metaDates.type4MetaData.date}} Uhr
          </b-tooltip>
        </span>
      </b-alert>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { db } from "../plugins/firebase"
import { ref, onValue } from "firebase/database"


export default {
  name: 'lastUpdate',
  data(){
    return{
      alertState: 'secondary',
      alertText: 'Lade Informationen ...',
      metaDates: null
    }
  },
  props: {
    page: String
  },
  methods: {
    lastUpdate(){
      onValue(ref(db, `/lastUpdate/${this.page}`), (snapshot) => {
        const data = snapshot.val()
        const dateFromDB = moment(data.stamp, "x")
        if (moment().diff(dateFromDB, 'days') >= 1){
          this.alertState = 'danger'
          this.alertText = `Letztes Update: ${dateFromDB.format('DD.MM.YYYY HH:mm')} Uhr`
        } else {
          this.alertState = 'info'
          this.alertText = `Letztes Update: ${dateFromDB.format('DD.MM.YYYY HH:mm')} Uhr`
        } 
      });
    },
    lastMetaUpdate(){
      onValue(ref(db, `/lastUpdate/metaExport`), (snapshot) => {
        this.metaDates = snapshot.val()
      })
    }
  },
  mounted(){
    this.lastUpdate();
    this.lastMetaUpdate();
  }
}
</script>

<style>
.tooltip .tooltip-inner{
  max-width: 400px !important;
  width: 350px !important;
}
</style>
